import type { Routine } from '@sb/types';
import { six } from '@sb/utilities';

export interface WidgetState {
  item?: Routine.SpaceItem;
  updateItem?: Routine.SpaceItem;
  removeItemID?: string;
  renameItem?: Routine.SpaceItem;
  createItemKind?: Routine.SpaceItem['kind'];
  currentPositionIndex?: number;
  isSettingCorners?: boolean;
}

export const UNSAVED_POSITION: Routine.Position = {
  jointAngles: six(NaN),
  pose: { i: NaN, j: NaN, k: NaN, w: NaN, x: NaN, y: NaN, z: NaN },
  name: 'UNSAVED',
};
