import { SquareButton } from '@sb/ui/components';
import { ArrowBackIcon } from '@sb/ui/icons';

interface BackButtonProps {
  onClick: () => void;
}

const BackButton = ({ onClick }: BackButtonProps) => {
  return (
    <SquareButton aria-label="Back" onClick={onClick}>
      <ArrowBackIcon size="small" />
    </SquareButton>
  );
};

export default BackButton;
