/* eslint-disable react/no-multi-comp,no-param-reassign */

import { useGLTF } from '@react-three/drei';
import { useThree } from '@react-three/fiber';
import { Suspense, useEffect, useLayoutEffect, useMemo, useRef } from 'react';
import type { Object3D } from 'three';
import { DoubleSide, Mesh } from 'three';

import type { CartesianPose } from '@sb/geometry';
import { ZERO_POSE } from '@sb/geometry';
import { LoaderFallback } from '@sb/visualizer';

interface VisualizeEnvironmentObjectProps {
  fileURL: string;
  pose?: CartesianPose;
}

function EnvironmentObjectInner({
  fileURL,
  pose = ZERO_POSE,
}: VisualizeEnvironmentObjectProps) {
  const gltf = useGLTF(fileURL);

  const scene = useMemo(() => {
    // clone the scene so it can appear multiple times if needed
    return gltf.scene.clone(true);
  }, [gltf.scene]);

  const invalidate = useThree((state) => state.invalidate);

  const objectRef = useRef<Object3D>(null!);

  useEffect(() => {
    objectRef.current.quaternion.set(pose.i, pose.j, pose.k, pose.w);
    objectRef.current.position.set(pose.x, pose.y, pose.z);
    invalidate();
  }, [pose, invalidate]);

  useLayoutEffect(() => {
    scene.traverse((child) => {
      if (child instanceof Mesh) {
        child.material.side = DoubleSide;
        child.material.transparent = true;
        child.material.opacity = 0.4;
      }
    });
  }, [scene]);

  return <primitive ref={objectRef} object={scene} />;
}

export function EnvironmentObject(props: VisualizeEnvironmentObjectProps) {
  return (
    <Suspense fallback={<LoaderFallback />}>
      <EnvironmentObjectInner {...props} />
    </Suspense>
  );
}
