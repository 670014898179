import cx from 'classnames';

import { Typography } from '@sb/ui/components';
import { margin } from '@sb/ui/styles';

interface WidgetTextProps {
  children: React.ReactNode;
  className?: string;
}

const WidgetText = ({ children, className }: WidgetTextProps) => {
  return (
    <Typography
      textAlign="center"
      variant="medium"
      className={cx(className, margin.bottom.medium)}
    >
      {children}
    </Typography>
  );
};

export default WidgetText;
