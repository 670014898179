import { useMemo } from 'react';
import { BufferGeometry, DoubleSide } from 'three';

import { getGridBoundingBox } from '@sb/geometry';
import type { Routine } from '@sb/types';

import { BoundaryLine } from './BoundaryLine';

interface VisualizeGridCornersProps {
  spaceItem: Routine.GridPositionList;
  isSettingCorners: boolean;
}

export function GridBoundingBox({
  spaceItem,
  isSettingCorners,
}: VisualizeGridCornersProps) {
  const cornerA = spaceItem.cornerA?.pose;
  const cornerB = spaceItem.cornerB?.pose;
  const cornerC = spaceItem.cornerC?.pose;
  const cornerD = spaceItem.cornerD?.pose;

  const boundingBox = useMemo(() => {
    const corners = getGridBoundingBox(
      { a: cornerA, b: cornerB, c: cornerC, d: cornerD },
      0.08,
    );

    if (!corners) {
      return null;
    }

    const geometry = new BufferGeometry()
      // triange1 = abd / triangle2 = dbc
      .setFromPoints([
        corners.a,
        corners.b,
        corners.d,
        corners.d,
        corners.b,
        corners.c,
      ]);

    return { ...corners, geometry };
  }, [cornerA, cornerB, cornerC, cornerD]);

  const color = isSettingCorners ? '#001eff' : '#ff95a1';

  if (!boundingBox) {
    return (
      <>
        <BoundaryLine start={cornerA} end={cornerB} color={color} dashed />
        <BoundaryLine start={cornerB} end={cornerC} color={color} dashed />
        <BoundaryLine start={cornerC} end={cornerD} color={color} dashed />
        <BoundaryLine start={cornerD} end={cornerA} color={color} dashed />
      </>
    );
  }

  return (
    <>
      <mesh geometry={boundingBox.geometry}>
        <meshBasicMaterial
          color={color}
          side={DoubleSide}
          transparent
          opacity={0.2}
        />
      </mesh>

      <BoundaryLine
        start={boundingBox.a}
        end={boundingBox.b}
        color={color}
        dashed={isSettingCorners}
      />
      <BoundaryLine
        start={boundingBox.b}
        end={boundingBox.c}
        color={color}
        dashed={isSettingCorners}
      />
      <BoundaryLine
        start={boundingBox.c}
        end={boundingBox.d}
        color={color}
        dashed={isSettingCorners}
      />
      <BoundaryLine
        start={boundingBox.d}
        end={boundingBox.a}
        color={color}
        dashed={isSettingCorners}
      />
    </>
  );
}
