import { useEffect, useState } from 'react';

import type { RangeSpinnerProps } from '@sb/ui/components';
import type { UseRoutineRunnerHandleArguments } from '@sbrc/hooks';

import useDualValuePayloadForm from './useDualValuePayloadForm';

/**
 * Returns the props for a `<RangeSpinner>` component to show a DualValuePayloadForm,
 * and the props form a `<Checkbox>` component for the user to confirm the robot is safe to unbrake.
 * We don't allow users to check the checkbox until both the target and actual payloads match.
 */
export default function useConfirmNoHumansAndPayload(
  props: UseRoutineRunnerHandleArguments,
): {
  payloadRangeSpinnerProps: RangeSpinnerProps;
  noHumansCheckboxProps: React.InputHTMLAttributes<HTMLInputElement>;
  isNoHumansConfirmed: boolean;
  setIsNoHumansConfirmed: (confirmed: boolean) => void;
  setTargetPayload: (massKG: number) => void;
} {
  const { payloadRangeSpinnerProps, isPayloadSaveDisabled, setTargetPayload } =
    useDualValuePayloadForm(props);

  const [isNoHumansConfirmed, setIsNoHumansConfirmed] =
    useState<boolean>(false);

  /**
   * When the payload "save" button is enabled, it means the target and actual payloads are divergent
   * and the user needs to save their changes before they can check there are no humans around the robot.
   */
  const isNoHumansCheckboxDisabled = !isPayloadSaveDisabled;

  useEffect(() => {
    // Uncheck the checkbox when it becomes disabled.
    if (isNoHumansCheckboxDisabled) {
      setIsNoHumansConfirmed(false);
    }
  }, [isNoHumansCheckboxDisabled]);

  const noHumansCheckboxProps: React.InputHTMLAttributes<HTMLInputElement> = {
    disabled: isNoHumansCheckboxDisabled,
    checked: isNoHumansConfirmed,
    onChange: (e) => setIsNoHumansConfirmed(e.target.checked),
    children:
      'I confirm that no humans are within reach of the robot and that the payload is correct.',
  };

  return {
    payloadRangeSpinnerProps,
    noHumansCheckboxProps,
    isNoHumansConfirmed,
    setIsNoHumansConfirmed,
    setTargetPayload,
  };
}
