import Image from 'next/image';
import Link from 'next/link';

const STANDARD_BOTS_LOGO_DEFAULT_WIDTH_PX = 154;
const STANDARD_BOTS_LOGO_DEFAULT_HEIGHT_PX = 15;

const LOGO_BACKGROUND_DARK = '/images/Logo-Navbar-Dark.svg';
const LOGO_BACKGROUND_LIGHT = '/images/Logo-Navbar-Light.svg';

interface StandardBotsLogoProps {
  className?: string;
  logoBackgroundColor?: 'light' | 'dark';
}

const StandardBotsLogo = ({
  className,
  logoBackgroundColor = 'dark',
}: StandardBotsLogoProps) => (
  <Link href="/" passHref className={className}>
    <Image
      alt="Standard Bots"
      src={
        logoBackgroundColor === 'dark'
          ? LOGO_BACKGROUND_DARK
          : LOGO_BACKGROUND_LIGHT
      }
      width={STANDARD_BOTS_LOGO_DEFAULT_WIDTH_PX}
      height={STANDARD_BOTS_LOGO_DEFAULT_HEIGHT_PX}
    />
  </Link>
);

export default StandardBotsLogo;
