import cx from 'classnames';

import { WarningIcon } from '@sb/ui/icons';

import styles from './WarningWidget.module.css';

interface WarningWidgetProps {
  children: React.ReactNode;
  className?: string;
}

const WarningWidget = ({ children, className }: WarningWidgetProps) => {
  return (
    <div className={cx(styles.warningWidget, className)}>
      <WarningIcon className={styles.warningIcon} />

      {children}
    </div>
  );
};

export default WarningWidget;
