import { useRouter } from 'next/router';

import { SquareButton } from '@sb/ui/components';
import { HomeIcon } from '@sb/ui/icons';

const HomeButton = () => {
  const { push } = useRouter();

  return (
    <SquareButton aria-label="Home" onClick={() => push('/')}>
      <HomeIcon size="small" />
    </SquareButton>
  );
};

export default HomeButton;
