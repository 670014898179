import { Typography } from '@sb/ui/components';

import HomeAndBackButtons from '../home-and-back-buttons/HomeAndBackButtons';

interface NavAndTitleProps {
  isHomeButtonHidden?: boolean;
  onReturn?: () => void;
  title: string;
}

export default function NavAndTitle({
  isHomeButtonHidden,
  onReturn,
  title,
}: NavAndTitleProps) {
  return (
    <>
      <HomeAndBackButtons
        isHomeButtonHidden={isHomeButtonHidden}
        onReturn={onReturn}
      />
      <Typography component="h4" hasNoWrap>
        {title}
      </Typography>
    </>
  );
}
