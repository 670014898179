import { Html } from '@react-three/drei';
import { useThree } from '@react-three/fiber';
import cx from 'classnames';
import { useEffect } from 'react';

import type { CartesianPosition } from '@sb/geometry';

import styles from './Puck.module.css';

interface PuckProps {
  position: CartesianPosition;
  label: string;
  variant: 'blue' | 'gray';
}

export function Puck({ position, label, variant }: PuckProps) {
  // need to invalidate on mount, otherwise (occasionally) the HTML labels don't show immediately
  const invalidate = useThree((state) => state.invalidate);
  useEffect(() => invalidate(), [invalidate]);

  return (
    <Html
      transform
      sprite
      distanceFactor={1}
      position={[position.x, position.y, position.z]}
      className={cx(styles.puck, styles[variant])}
      pointerEvents="none"
      occlude="blending"
      geometry={<sphereGeometry args={[0.037]} />}
    >
      {label}
    </Html>
  );
}
