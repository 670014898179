import { useLayoutEffect, useState } from 'react';

import {
  PAYLOAD_MASS_KG_DEFAULT,
  PAYLOAD_MASS_KG_ABSOLUTE_MAX,
  PAYLOAD_MASS_KG_ABSOLUTE_MIN,
} from '@sb/routine-runner';
import type { RangeSpinnerProps } from '@sb/ui/components';
import { Button, useDualValueRangeSpinner } from '@sb/ui/components';
import { massToString } from '@sb/utilities';
import type { UseRoutineRunnerHandleArguments } from '@sbrc/hooks';
import {
  useIsAnotherSessionRunningAdHocCommand,
  useRobotPayloadState,
  useRoutineRunnerHandle,
  useToast,
} from '@sbrc/hooks';
import { PAYLOAD_KG_SLIDER_STEP } from '@sbrc/utils';

/**
 * Returns the props for a `<RangeSpinner>` component which shows the current actual payload
 * configured on the `routineRunnerHandle`, and allows the user to select a new target payload.
 * The control includes a "Save" button which is enabled when the target payload is
 * different from the actual payload; when pressed, `routineRunnerHandle.setRobotPayload`
 * method is called with the new target payload
 */
export default function useDualValuePayloadForm({
  isVizbot,
  robotID,
}: UseRoutineRunnerHandleArguments): {
  payloadRangeSpinnerProps: RangeSpinnerProps;
  isPayloadSaveDisabled: boolean;
  targetPayload: number;
  /** `setTargetPayload(NaN)` will reset to actual */
  setTargetPayload: (mass: number) => void;
} {
  const { setToast } = useToast();

  const routineRunnerArgs = { isVizbot, robotID };

  const routineRunnerHandle = useRoutineRunnerHandle(routineRunnerArgs);

  const payloadKg = useRobotPayloadState(routineRunnerArgs);
  const actualPayload = payloadKg ?? PAYLOAD_MASS_KG_DEFAULT;

  const [targetPayload, setTargetPayload] = useState<number>(actualPayload);

  useLayoutEffect(() => {
    if (Number.isNaN(targetPayload) && !Number.isNaN(actualPayload)) {
      setTargetPayload(actualPayload);
    }
  }, [targetPayload, actualPayload]);

  const [isSaving, setIsSaving] = useState<boolean>(false);

  const isAnotherSessionMovingRobot = useIsAnotherSessionRunningAdHocCommand({
    robotID,
  });

  const setActualPayload = async () => {
    setIsSaving(true);

    try {
      setToast({
        kind: 'progress',
        message: 'Setting payload...',
      });

      await routineRunnerHandle.setRobotPayload({ mass: targetPayload });

      setToast({
        kind: 'success',
        message: 'Payload successfully set',
      });
    } catch (error) {
      setToast({ kind: 'error', message: error.message });
    } finally {
      setIsSaving(false);
    }
  };

  const spinnerProps = useDualValueRangeSpinner({
    primaryValue: actualPayload,
    secondaryValue: targetPayload,
    min: PAYLOAD_MASS_KG_ABSOLUTE_MIN,
    max: PAYLOAD_MASS_KG_ABSOLUTE_MAX,
    step: PAYLOAD_KG_SLIDER_STEP,
    isDisabled: isSaving,
    onChange: setTargetPayload,
  });

  const isPayloadSaveDisabled = Boolean(
    isSaving || spinnerProps.areValuesEqual || isAnotherSessionMovingRobot,
  );

  return {
    payloadRangeSpinnerProps: {
      ...spinnerProps,
      isDisabled: isSaving,
      valueToString: massToString,
      children: (
        <>
          {spinnerProps.children}
          <Button
            disabled={isPayloadSaveDisabled}
            variant="secondary"
            onClick={setActualPayload}
            data-testid="save-payload-button-synclive--robot-position"
          >
            Save
          </Button>
        </>
      ),
    },
    isPayloadSaveDisabled,
    targetPayload,
    setTargetPayload,
  };
}
