import { useMemo } from 'react';

import type {
  RoutineRunningPauseDetails,
  RoutineRunningPauseKind,
} from '@sb/routine-runner';
import type { Robot } from '@sb/types';
import { Button } from '@sb/ui/components';
import { PlayIcon } from '@sb/ui/icons';
import WidgetText from '@sbrc/components/visualizer-view-shared/widget-panel/WidgetText';
import {
  useIsRobotBraked,
  usePlayRobot,
  useRobotPauseDetails,
} from '@sbrc/hooks';

import { UnbrakeButton } from '../unbrake-button/UnbrakeButton';

import WarningWidget from './WarningWidget';

const HIDE_WIDGET_FOR_KINDS: RoutineRunningPauseKind[] = [
  'guidedModeInvalidation',
  'user',
  'waitForConfirmation',
];

interface UsePausedWidgetArgs {
  isVizbot: boolean;
  robot: Robot.ConvertedResponse;
}

export function usePausedWidget({
  isVizbot,
  robot,
}: UsePausedWidgetArgs): React.ReactNode {
  const routineRunnerArgs = { isVizbot, robotID: robot.id };

  const pauseDetails = useRobotPauseDetails(routineRunnerArgs);

  const isBraked = useIsRobotBraked(routineRunnerArgs);

  const { onPlay } = usePlayRobot({ robot, isVizbot });

  const pauseDetail = useMemo<RoutineRunningPauseDetails | undefined>(() => {
    const filteredPauseDetails = pauseDetails.filter(
      (d) => !HIDE_WIDGET_FOR_KINDS.includes(d.kind),
    );

    return (
      filteredPauseDetails.find((d) => !d.isAutoResume) ??
      filteredPauseDetails[0]
    );
  }, [pauseDetails]);

  if (!pauseDetail) {
    return null;
  }

  return (
    <WarningWidget>
      {pauseDetail.reason && <WidgetText>{pauseDetail.reason}</WidgetText>}

      <WidgetText>
        <strong>The routine was paused automatically.</strong>
        {pauseDetail.isAutoResume &&
          !isBraked &&
          ' The routine will automatically resume.'}
      </WidgetText>

      {isBraked && <UnbrakeButton isVizbot={isVizbot} robotID={robot.id} />}

      {!pauseDetail.isAutoResume && !isBraked && (
        <Button startIcon={<PlayIcon />} onClick={() => onPlay()} isFullWidth>
          Resume Routine
        </Button>
      )}
    </WarningWidget>
  );
}
