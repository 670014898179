import {
  PAYLOAD_MASS_KG_ABSOLUTE_MAX,
  PAYLOAD_MASS_KG_ABSOLUTE_MIN,
} from '@sb/routine-runner';
import {
  RangeSpinner,
  useSingleValueRangeSpinner,
  WizardCard,
  Typography,
} from '@sb/ui/components';
import { massToString } from '@sb/utilities';
import { PAYLOAD_KG_SLIDER_STEP } from '@sbrc/utils';

interface PayloadSliderProps {
  payloadKg: number;
  onPayloadKgChange: React.Dispatch<React.SetStateAction<number>>;
  title?: string;
  helperText?: string;
  isDisabled?: boolean;
}

/**
 * This is specifically a PayloadSlider for step wizard forms to use.
 */
export function PayloadSlider({
  payloadKg,
  isDisabled,
  onPayloadKgChange,
  title = 'Payload',
  helperText,
}: PayloadSliderProps) {
  const spinnerProps = useSingleValueRangeSpinner({
    value: payloadKg,
    min: PAYLOAD_MASS_KG_ABSOLUTE_MIN,
    max: PAYLOAD_MASS_KG_ABSOLUTE_MAX,
    step: PAYLOAD_KG_SLIDER_STEP,
    onChange: onPayloadKgChange,
  });

  return (
    <WizardCard title={title}>
      {helperText && <Typography>{helperText}</Typography>}
      <RangeSpinner
        {...spinnerProps}
        isDisabled={isDisabled}
        ariaLabel="payload"
        valueToString={massToString}
      />
    </WizardCard>
  );
}
