import type { DeviceCommand } from '@sb/integrations/device';
import { getEquipmentByKind } from '@sb/integrations/frontend/getEquipmentByKind';
import type { Robot } from '@sb/types';
import { useEquipment } from '@sbrc/hooks';

interface EquipmentWidgetsProps {
  robot: Robot.ConvertedResponse;
  isVizbot: boolean;
  activeWidget?: string | null;
  onClose: () => void;
  onCommandChange?: React.Dispatch<React.SetStateAction<DeviceCommand | null>>;
  endEffectorDefaultCommand?: DeviceCommand;
}

export function EquipmentWidget({
  robot,
  isVizbot,
  activeWidget,
  onClose,
  onCommandChange,
  endEffectorDefaultCommand,
}: EquipmentWidgetsProps) {
  const equipment = useEquipment();

  const equipmentItem = equipment?.find(
    ({ config }) => activeWidget === `equipment-${config.kind}`,
  );

  if (!equipmentItem) {
    return null;
  }

  const implementation = getEquipmentByKind(equipmentItem.config.kind);

  if (!implementation.Widget) {
    return null;
  }

  return (
    <implementation.Widget
      endEffector={equipmentItem.config}
      implementation={implementation}
      isVizbot={isVizbot}
      robot={robot}
      onClose={onClose}
      onCommandChange={onCommandChange}
      defaultCommand={endEffectorDefaultCommand}
      getEquipmentByKind={getEquipmentByKind}
    />
  );
}
