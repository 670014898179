import { IconButton, Popover, Typography } from '@sb/ui/components';

interface WidgetIconButtonProps<WidgetKind extends string>
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  forWidget: WidgetKind;
  activeWidget: WidgetKind | null;
  setActiveWidget: (kind: WidgetKind | null) => void;
  label: string;
  icon: React.ReactElement;
  tooltip: string;
}

export function WidgetIconButton<WidgetKind extends string>({
  forWidget,
  activeWidget,
  setActiveWidget,
  label,
  icon,
  tooltip,
  ...rest
}: WidgetIconButtonProps<WidgetKind>) {
  return (
    <Popover content={<Typography>{tooltip}</Typography>}>
      <IconButton
        variant="white"
        onClick={() =>
          setActiveWidget(forWidget === activeWidget ? null : forWidget)
        }
        label={activeWidget ? undefined : label}
        aria-label={label}
        isActive={!rest.disabled && forWidget === activeWidget}
        {...rest}
      >
        {icon}
      </IconButton>
    </Popover>
  );
}
