import type { WidgetState } from '../types';

import { GridBoundingBox } from './GridBoundingBox';
import { GridCorners } from './GridCorners';
import { Positions } from './Positions';

interface VisualizeSpaceItemProps {
  widgetState: WidgetState;
}

export function VisualizeSpaceItem({ widgetState }: VisualizeSpaceItemProps) {
  const spaceItem = widgetState.item;

  const isSettingCorners =
    spaceItem?.positions.length === 0 || !!widgetState.isSettingCorners;

  const currentPositionIndex = widgetState.currentPositionIndex ?? 0;

  return (
    <>
      {spaceItem?.kind === 'gridPositionList' && isSettingCorners && (
        <GridCorners spaceItem={spaceItem} />
      )}

      {spaceItem?.kind === 'gridPositionList' && (
        <GridBoundingBox
          spaceItem={spaceItem}
          isSettingCorners={isSettingCorners}
        />
      )}

      {spaceItem && !isSettingCorners && (
        <Positions
          spaceItem={spaceItem}
          currentPositionIndex={currentPositionIndex}
        />
      )}
    </>
  );
}
