import { useRouter } from 'next/router';

import { BackgroundCover, Button, Typography } from '@sb/ui/components';
import { margin } from '@sb/ui/styles';
import {
  STANDARD_BOTS_BACKGROUND_IMAGE_SRC,
  STANDARD_BOTS_ERROR_BACKGROUND_SRC,
} from '@sbrc/utils';

import StandardBotsLogo from '../StandardBotsLogo';

import styles from './ErrorPageLayout.module.css';

interface ErrorPageLayoutProps {
  actionButton?: { label: string; link: string };
  message: string;
  statusCode?: number;
}

const ErrorPageLayout = ({
  actionButton,
  message,
  statusCode,
}: ErrorPageLayoutProps) => {
  const { push } = useRouter();

  return (
    <BackgroundCover
      src={
        statusCode === 404
          ? STANDARD_BOTS_ERROR_BACKGROUND_SRC
          : STANDARD_BOTS_BACKGROUND_IMAGE_SRC
      }
      className={styles.errorPageLayout}
    >
      <StandardBotsLogo className={styles.logo} />

      <div className={styles.errorMessage}>
        <Typography
          isBold
          color="white"
          className={margin.bottom.extraSmall}
          component="h4"
        >
          Oops!
        </Typography>
        <Typography
          isBold
          color="white"
          className={actionButton ? margin.bottom.medium : undefined}
          variant="medium"
        >
          {message}
        </Typography>
      </div>

      {actionButton && (
        <Button
          className={styles.errorButton}
          onClick={() => push(actionButton.link)}
        >
          {actionButton.label}
        </Button>
      )}
    </BackgroundCover>
  );
};

export default ErrorPageLayout;
