import cx from 'classnames';

import { FailureKind } from '@sb/routine-runner';
import type { Robot } from '@sb/types';
import { Button, Checkbox, RangeSpinner, Typography } from '@sb/ui/components';
import { margin, padding } from '@sb/ui/styles';
import {
  useGuidedMode,
  useRobotFailureState,
  useRoutineRunnerHandle,
  useToast,
} from '@sbrc/hooks';

import { useConfirmNoHumansAndPayload } from '../../payload';
import WarningWidget from '../warning-widget/WarningWidget';
import WidgetText from '../widget-panel/WidgetText';

import styles from './RecoveryWidget.module.css';

interface RecoveryWidgetProps {
  isVizbot: boolean;
  robot: Robot.ConvertedResponse;
}

export default function RecoveryWidget({
  isVizbot,
  robot,
}: RecoveryWidgetProps) {
  const robotID = robot.id;

  const routineRunnerArgs = { isVizbot, robotID };

  const routineRunnerHandle = useRoutineRunnerHandle(routineRunnerArgs);

  const { setToast } = useToast();

  const { runAdHocCommand, stopGuidedMode } = useGuidedMode(routineRunnerArgs);

  const failureState = useRobotFailureState(routineRunnerArgs);

  const isCollisionFailureKind =
    failureState?.failure.kind === FailureKind.CollisionFailure;

  const handleRecover = async (): Promise<void> => {
    try {
      await routineRunnerHandle.recover();
    } catch (error) {
      setToast({
        kind: 'error',
        message: error.message ?? error,
      });
    }
  };

  const handleGuidedModeRecover = async () => {
    return runAdHocCommand({ onRunCommand: handleRecover });
  };

  const showSafetyConfirmation = !isVizbot && isCollisionFailureKind;

  const {
    payloadRangeSpinnerProps,
    noHumansCheckboxProps,
    isNoHumansConfirmed,
  } = useConfirmNoHumansAndPayload({
    robotID: robot.id,
  });

  const holdToRecoverButton = (() => {
    if (!failureState) {
      return null;
    }

    switch (failureState.recoveryType) {
      case 'Restart':
        return (
          <WidgetText className={styles.textContainer}>
            You must restart the robot to recover from this failure. If this
            failure persists contact support.
          </WidgetText>
        );
      case 'NotRecoverable':
        return (
          <WidgetText className={styles.textContainer}>
            The robot is in a non recoverable state. Please contact support for
            further assistance.
          </WidgetText>
        );
      case 'GuidedMode':
        return (
          <Button
            isFullWidth
            disabled={showSafetyConfirmation && !isNoHumansConfirmed}
            onHold={handleGuidedModeRecover}
            onRelease={stopGuidedMode}
            variant="primary"
            data-testid="tap-to-recover-button-for-robot-recovery"
          >
            <strong>Hold</strong> to Recover
          </Button>
        );
      default:
        return (
          <Button
            isFullWidth
            onClick={handleRecover}
            variant="primary"
            data-testid="tap-to-recover-button-for-robot-recovery"
          >
            <strong>Tap</strong> to Recover
          </Button>
        );
    }
  })();

  const introMessage = (
    <>
      <strong>Robot needs Recovery.</strong>
      <br />
      {isVizbot ? 'Visualizer' : 'Live Robot'} has reported an error:
    </>
  );

  const failureReasonMessage = failureState?.failureReason;

  if (showSafetyConfirmation) {
    return (
      <WarningWidget className={styles.warningPanel}>
        <WidgetText className={styles.textContainer}>{introMessage}</WidgetText>
        <WidgetText className={styles.textContainer}>
          {failureReasonMessage}
        </WidgetText>
        <div className={cx(styles.container, padding.top.extraSmall)}>
          <Typography
            className={margin.bottom.small}
            isBold
            isUppercase
            variant="extraSmall"
          >
            Current Payload
          </Typography>
          <RangeSpinner {...payloadRangeSpinnerProps} />
        </div>
        <div className={cx(styles.container)}>
          <Checkbox {...noHumansCheckboxProps} className={margin.top.small} />
          {holdToRecoverButton}
        </div>
      </WarningWidget>
    );
  }

  return (
    <WarningWidget>
      <WidgetText>{introMessage}</WidgetText>
      <WidgetText>{failureReasonMessage}</WidgetText>
      {holdToRecoverButton}
    </WarningWidget>
  );
}
