import { Line } from '@react-three/drei';
import type { ColorRepresentation } from 'three';

import type { CartesianPosition } from '@sb/geometry';

interface DottedLineProps {
  start?: CartesianPosition;
  end?: CartesianPosition;
  color: ColorRepresentation;
  dashed?: boolean;
}

export function BoundaryLine({ start, end, color, dashed }: DottedLineProps) {
  if (!start || !end) {
    return null;
  }

  return (
    <Line
      points={[
        [start.x, start.y, start.z],
        [end.x, end.y, end.z],
      ]}
      color={color}
      dashed={dashed}
      dashSize={0.01}
      gapSize={0.01}
      lineWidth={1}
    />
  );
}
