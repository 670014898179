import { useMemo } from 'react';

import { getGridBoundingBox } from '@sb/geometry';
import type { Routine } from '@sb/types';

import { Puck } from './Puck';

interface VisualizeGridCornersProps {
  spaceItem: Routine.GridPositionList;
}

export function GridCorners({ spaceItem }: VisualizeGridCornersProps) {
  const corners = useMemo(() => {
    const knownCorners = {
      a: spaceItem.cornerA?.pose,
      b: spaceItem.cornerB?.pose,
      c: spaceItem.cornerC?.pose,
      d: spaceItem.cornerD?.pose,
    };

    return getGridBoundingBox(knownCorners) ?? knownCorners;
  }, [spaceItem]);

  return (
    <>
      {corners?.a && <Puck position={corners.a} label="A" variant="blue" />}
      {corners?.b && <Puck position={corners.b} label="B" variant="blue" />}
      {corners?.c && <Puck position={corners.c} label="C" variant="blue" />}
      {corners?.d && <Puck position={corners.d} label="D" variant="blue" />}
    </>
  );
}
