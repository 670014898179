import type { Robot } from '@sb/types';
import { IOIcon } from '@sb/ui/icons';

import WidgetView from '../widget-panel/WidgetView';

import { IOWidgetTabs } from './IOWidgetTabs';

interface IOWidgetProps {
  isVizbot: boolean;
  onClose: () => void;
  robot: Robot.ConvertedResponse;
}

export function IOWidget({ isVizbot, onClose, robot }: IOWidgetProps) {
  return (
    <WidgetView
      onClose={onClose}
      hasNoBorder
      headerTitle="I/O"
      headerIcon={<IOIcon />}
    >
      <IOWidgetTabs isVizbot={isVizbot} robot={robot} />
    </WidgetView>
  );
}
