import { Button, Checkbox, RangeSpinner, Typography } from '@sb/ui/components';
import { BrakeIcon } from '@sb/ui/icons';
import { useRobotUnbrake } from '@sbrc/hooks';

import { useConfirmNoHumansAndPayload } from '../../payload';

import styles from './UnbrakeButton.module.css';

interface UnbrakeButtonProps {
  isVizbot: boolean;
  robotID: string;
  testID?: string;
}

export function UnbrakeButton({
  isVizbot,
  robotID,
  testID,
}: UnbrakeButtonProps) {
  const {
    payloadRangeSpinnerProps,
    noHumansCheckboxProps,
    isNoHumansConfirmed,
  } = useConfirmNoHumansAndPayload({
    isVizbot,
    robotID,
  });

  const { unbrakeRobot: unbrakeControlViewRobot } = useRobotUnbrake({
    robotID,
    isVizbot,
  });

  return (
    <>
      {!isVizbot && (
        <>
          <div className={styles.payloadForm}>
            <Typography isBold isUppercase variant="extraSmall">
              Live Robot Payload
            </Typography>
            <RangeSpinner {...payloadRangeSpinnerProps} />
          </div>
          <Checkbox {...noHumansCheckboxProps} className={styles.checkbox} />
        </>
      )}

      <Button
        disabled={!isNoHumansConfirmed && !isVizbot}
        isFullWidth
        onClick={unbrakeControlViewRobot}
        variant="primary"
        startIcon={<BrakeIcon />}
        data-testid={testID}
      >
        Unbrake
      </Button>
    </>
  );
}
