import { LINK_TO_ROBOT_LIST_PAGE } from '@sbrc/utils';

import ErrorPageLayout from '../layouts/ErrorPageLayout';

export default function RobotNotFoundError() {
  return (
    <ErrorPageLayout
      message="Robot not found"
      statusCode={404}
      actionButton={{
        label: 'See all robots',
        link: LINK_TO_ROBOT_LIST_PAGE,
      }}
    />
  );
}
