import { PivotControls } from '@react-three/drei';
import { useMemo, useState } from 'react';

import type { CartesianPose } from '@sb/geometry';
import { cartesianPoseToMatrix4, matrix4ToCartesianPose } from '@sb/geometry';
import type { Three } from '@sb/utilities';

interface EnvironmentObjectPivotControlsProps {
  children: React.ReactNode;
  initialPose: CartesianPose;
  onDragEnd: (pose: CartesianPose) => void;
}

export function EnvironmentObjectPivotControls({
  children,
  initialPose,
  onDragEnd,
}: EnvironmentObjectPivotControlsProps) {
  const axisColors = useMemo(() => {
    return [
      '--standard-bots--x-color',
      '--standard-bots--y-color',
      '--standard-bots--z-color',
    ].map((color) =>
      getComputedStyle(document.body).getPropertyValue(color).trim(),
    ) as Three<string>;
  }, []);

  const [matrix] = useState(() => cartesianPoseToMatrix4(initialPose));

  return (
    <PivotControls
      scale={0.7}
      axisColors={axisColors}
      annotations
      matrix={matrix}
      autoTransform={false}
      onDrag={(localMatrix) => {
        matrix.copy(localMatrix);
      }}
      onDragEnd={() => {
        onDragEnd(matrix4ToCartesianPose(matrix));
      }}
    >
      {children}
    </PivotControls>
  );
}
