import cx from 'classnames';

import styles from './WidgetButtonGroup.module.css';

interface WidgetGroupProps {
  children?: React.ReactNode;
  position?: 'left' | 'right';
}

const WidgetButtonGroup = ({
  children,
  position = 'right',
}: WidgetGroupProps) => {
  return (
    <div className={cx(styles.widgetButtonGroup, styles[position])}>
      {children}
    </div>
  );
};

export default WidgetButtonGroup;
