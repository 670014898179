import { useMemo } from 'react';

import type { Routine } from '@sb/types';

import { UNSAVED_POSITION } from '../types';

import { Puck } from './Puck';

interface VisualizeGridCornersProps {
  spaceItem: Routine.SpaceItem;
  currentPositionIndex: number;
}

const MAX_TO_SHOW = 50;

export function Positions({
  spaceItem,
  currentPositionIndex,
}: VisualizeGridCornersProps) {
  const positions = useMemo(() => {
    const mappedPositions = spaceItem.positions
      .map((position, index) => ({
        key: index,
        label: spaceItem.kind === 'singlePosition' ? '' : `${index}`,
        position,
        isCurrentPosition: index === currentPositionIndex,
      }))
      // filter out unsaved positions
      .filter(({ position }) => position !== UNSAVED_POSITION);

    const downsampleFactor =
      Math.floor(mappedPositions.length / MAX_TO_SHOW) + 1;

    if (downsampleFactor === 1) {
      return mappedPositions;
    }

    // lots of positions, just show some
    return mappedPositions.filter(
      ({ isCurrentPosition }, i) =>
        i % downsampleFactor === 0 || isCurrentPosition,
    );
  }, [spaceItem.positions, spaceItem.kind, currentPositionIndex]);

  return (
    <>
      {positions.map(({ key, label, position, isCurrentPosition }) => {
        return (
          <Puck
            key={key}
            position={position.pose}
            label={label}
            variant={isCurrentPosition ? 'blue' : 'gray'}
          />
        );
      })}
    </>
  );
}
