import { useRouter } from 'next/router';

import BackButton from './BackButton';
import HomeButton from './HomeButton';

import styles from './HomeAndBackButtons.module.css';

interface HomeAndBackButtonsProps {
  isHomeButtonHidden?: boolean;
  onReturn?: () => void;
}

const HomeAndBackButtons = ({
  isHomeButtonHidden,
  onReturn,
}: HomeAndBackButtonsProps) => {
  const { back } = useRouter();

  return (
    <div className={styles.homeAndBackButtons}>
      {!isHomeButtonHidden && <HomeButton />}
      <BackButton onClick={onReturn || back} />
    </div>
  );
};

export default HomeAndBackButtons;
